<template>
  <table v-if="bookings.length" class="table table-bordermargin table-hover mt-4">
    <tbody>
      <tr v-for="(booking, index) in bookings" :key="index" @click="$router.push({ name: 'booking-detail', params: { id: booking.id } })">
        <th scope="row">{{ booking.bookingNumber }}<br /><booking-status :booking="booking" /></th>
        <td>
          {{ booking.name }} <span class="badge badge-info">{{ booking.memberNumber }}</span>
          <br />
          <small class="text-blue"> {{ booking.accommodation }} </small>
          <small v-if="booking.guestCount > 0" class="text-blue">, {{ booking.guestCount }} personen </small>
        </td>
        <td>
          {{ booking.startDate | moment("DD-MM-YYYY") }} - {{ booking.endDate | moment("DD-MM-YYYY") }}<br />
          <small class="text-blue">{{ booking.nightCount }} nachten</small>
        </td>
        <td>{{ booking.bookingTotal | currency() }}</td>
        <td>
          {{ booking.modifiedOn | moment("from") }}<br /><small class="text-blue">{{ booking.modifiedOn | moment("HH:mm uur") }}</small>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
</style>

<script>
import config from "@/config";
import BookingStatus from "@/components/common/Status/BookingStatus";

export default {
  components: { BookingStatus },

  props: {
    bookings: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },

  data() {
    return {
      config: config
    };
  }
};
</script>
