<template>
  <div class="widget px-3 pt-3 pb-2">
    <i class="fas fa-calendar-alt icon"> </i>
    <span class="title ml-2">{{ $t("dashboard.metrics.Bookings.title").toUpperCase() }}</span>
    <div class="row mt-2">
      <div class="col">
        <span class="explain">Kort verblijf</span>
        <br />
        <span class="metric">
          <span> {{ shortStay.Occupied }} </span>
        </span>
      </div>
      <div class="col">
        <span class="explain">Lang verblijf</span>
        <br />
        <span class="metric">
          <span> {{ longStay.Occupied }} </span>
        </span>
      </div>
      <div class="col">
        <span class="explain">Stalling</span>
        <br />
        <span class="metric">
          <span> {{ storage.Occupied }} </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const MetricRepository = RepositoryFactory.get("MetricRepository");
import moment from "moment";

export default {
  data() {
    return {
      shortStay: {
        Available: 0,
        Occupied: 0,
        Capacity: 0
      },
      longStay: {
        Available: 0,
        Occupied: 0,
        Capacity: 0
      },
      storage: {
        Available: 0,
        Occupied: 0,
        Capacity: 0
      }
    };
  },

  computed: {
    ...mapState("Campsites", ["currentCampsite"])
  },

  watch: {
    currentCampsite() {
      this.getMetrics();
    }
  },

  mounted() {
    this.getMetrics();
  },

  methods: {
    getMetrics: function () {
      MetricRepository.getOccupancy(this.currentCampsite.id, moment().startOf("day").toDate(), moment().endOf("day").toDate(), "ShortStay").then((response) => {
        response.data.dimensions.forEach((e) => (this.shortStay[e.name] = e.value));
      });
      MetricRepository.getOccupancy(this.currentCampsite.id, moment().startOf("day").toDate(), moment().endOf("day").toDate(), "LongStay").then((response) => {
        response.data.dimensions.forEach((e) => (this.longStay[e.name] = e.value));
      });
      MetricRepository.getOccupancy(this.currentCampsite.id, moment().startOf("day").toDate(), moment().endOf("day").toDate(), "LongStorage").then((response) => {
        response.data.dimensions.forEach((e) => (this.storage[e.name] = e.value));
      });
    }
  }
};
</script>
