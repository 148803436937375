<template>
  <div>
    <b-row>
      <b-col sm="6" lg="4" xl="4" class="mt-4 mb-4">
        <metric-arrivals />
      </b-col>
      <b-col sm="6" lg="4" xl="4" class="mt-4 mb-4">
        <metric-departures />
      </b-col>
      <b-col sm="8" md="6" lg="4" xl="4" class="mt-4 mb-4">
        <metric-guests />
      </b-col>
      <b-col md="6" lg="6" xl="6" class="mt-4 mb-4">
        <metric-availability />
      </b-col>
      <b-col sm="12" lg="6" xl="6" class="mt-4 mb-4">
        <metric-occupancy />
      </b-col>
    </b-row>
    <b-row class="flex-grow-1">
      <b-col lg="12" xl="6" class="pt-3">
        <bookings-overview />
      </b-col>
      <b-col lg="12" xl="6" class="pt-3 bg-light">
        <turnover-overview />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MetricArrivals from "./MetricArrivals.vue";
import MetricDepartures from "./MetricDepartures.vue";
import MetricAvailability from "./MetricAvailability.vue";
import MetricOccupancy from "./MetricOccupancy.vue";
import MetricGuests from "./MetricGuests.vue";

import BookingsOverview from "./BookingsOverview.vue";
import TurnoverOverview from "./TurnoverOverview.vue";

export default {
  components: { BookingsOverview, TurnoverOverview, MetricArrivals, MetricDepartures, MetricAvailability, MetricOccupancy, MetricGuests }
};
</script>
