<template>
  <div class="widget px-3 pt-3 pb-2">
    <i class="fas fa-calendar-week icon"> </i>
    <span class="title ml-2">{{ $t("dashboard.metrics.Availability.title").toUpperCase() }}</span>
    <div class="row mt-2">
      <div class="col">
        <span class="explain">Plaatsen</span>
        <br />
        <span class="metric">
          <span> {{ stay.Available }}</span>
          <span class="suffix">/ {{ stay.Capacity }} </span>
        </span>
      </div>
      <div class="col">
        <span class="explain">Stalling</span>
        <br />
        <span class="metric">
          <span> {{ storage.Available }}</span>
          <span class="suffix">/ {{ storage.Capacity }} </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const MetricRepository = RepositoryFactory.get("MetricRepository");
import moment from "moment";

export default {
  data() {
    return {
      stay: {
        Available: 0,
        Booked: 0,
        Capacity: 0
      },
      storage: {
        Available: 0,
        Booked: 0,
        Capacity: 0
      }
    };
  },

  computed: {
    ...mapState("Campsites", ["currentCampsite"])
  },

  watch: {
    currentCampsite() {
      this.getMetrics();
    }
  },

  mounted() {
    this.getMetrics();
  },

  methods: {
    getMetrics: function () {
      MetricRepository.getAvailability(this.currentCampsite.id, moment().startOf("day").toDate(), moment().endOf("day").toDate(), "CampingPitch").then((response) => {
        response.data.dimensions.forEach((e) => (this.stay[e.name] = e.value));
      });
      MetricRepository.getAvailability(this.currentCampsite.id, moment().startOf("day").toDate(), moment().endOf("day").toDate(), "StoragePitch").then((response) => {
        response.data.dimensions.forEach((e) => (this.storage[e.name] = e.value));
      });
    }
  }
};
</script>
