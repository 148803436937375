<template>
  <div>
    <b-dropdown variant="light" size="sm" right :text="rangeOptions[rangeOption].text" class="float-right">
      <b-dropdown-item v-for="(item, index) in rangeOptions" :key="index" @click="onRangeChanged(index)">{{ item.text }} </b-dropdown-item>
    </b-dropdown>

    <loading-spinner v-if="loading" :loading="loading" />

    <h1>Boekingen</h1>

    <b-tabs>
      <b-tab active>
        <template slot="title">
          Nieuw
          <b-badge v-b-tooltip.hover variant="blue" title="Totaal nieuwe boekingen">{{ createdBookings.length }}</b-badge>
        </template>
        <bookings-overview-table v-if="createdBookings.length" :bookings="createdBookings" />
        <b-alert v-else variant="light" class="mt-5" show>Geen boekingen gevonden.</b-alert>
      </b-tab>
      <b-tab>
        <template slot="title">
          Gewijzigd
          <b-badge v-b-tooltip.hover variant="blue" title="Totaal gewijzigde boekingen">{{ modifiedBookings.length }}</b-badge>
        </template>
        <bookings-overview-table v-if="modifiedBookings.length" :bookings="modifiedBookings" />
        <b-alert v-else variant="light" class="mt-5" show>Geen boekingen gevonden.</b-alert>
      </b-tab>
      <b-tab>
        <template slot="title">
          Geannuleerd
          <b-badge v-b-tooltip.hover variant="blue" title="Totaal geannuleerde boekingen">{{ canceledBookings.length }}</b-badge>
        </template>
        <bookings-overview-table v-if="canceledBookings.length" :bookings="canceledBookings" />
        <b-alert v-else variant="light" class="mt-5" show>Geen boekingen gevonden.</b-alert>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const BookingRepository = RepositoryFactory.get("BookingRepository");

import LoadingSpinner from "@/components/common/LoadingSpinner.vue";
import BookingsOverviewTable from "./BookingsOverviewTable";

export default {
  components: { BookingsOverviewTable, LoadingSpinner },

  data() {
    return {
      createdBookings: [],
      modifiedBookings: [],
      canceledBookings: [],
      rangeOption: 0,
      loading: false
    };
  },

  computed: {
    ...mapState("Campsites", ["currentCampsite"]),
    rangeOptions() {
      return [
        {
          text: "Vandaag",
          value: {
            from: this.$moment().startOf("day").toDate(),
            to: this.$moment().endOf("day").toDate()
          }
        },
        {
          text: "Gisteren",
          value: {
            from: this.$moment().subtract({ days: 1 }).startOf("day").toDate(),
            to: this.$moment().subtract({ days: 1 }).endOf("day").toDate()
          }
        },
        {
          text: "Eergisteren",
          value: {
            from: this.$moment().subtract({ days: 2 }).startOf("day").toDate(),
            to: this.$moment().subtract({ days: 2 }).endOf("day").toDate()
          }
        },
        {
          text: "Afgelopen 7 dagen",
          value: {
            from: this.$moment().subtract({ days: 7 }).startOf("day").toDate(),
            to: this.$moment().endOf("day").toDate()
          }
        }
      ];
    }
  },

  watch: {
    currentCampsite() {
      this.fetchBookings(this.rangeOptions[this.rangeOption].value);
    }
  },

  mounted() {
    this.fetchBookings(this.rangeOptions[this.rangeOption].value);
  },
  methods: {
    onRangeChanged(index) {
      this.rangeOption = index;

      this.fetchBookings(this.rangeOptions[this.rangeOption].value);
    },
    async fetchBookings(range) {
      this.loading = true;

      await BookingRepository.getCreated(this.currentCampsite.id, range.from, range.to, 0, 99).then((bookings) => {
        this.createdBookings = bookings;
      });

      await BookingRepository.getModified(this.currentCampsite.id, range.from, range.to, 0, 99).then((bookings) => {
        this.modifiedBookings = bookings;
      });

      await BookingRepository.getCanceled(this.currentCampsite.id, range.from, range.to, 0, 99).then((bookings) => {
        this.canceledBookings = bookings;
      });

      this.loading = false;
    }
  }
};
</script>
