<template>
  <div class="widget px-3 pt-3 pb-2">
    <i class="fas fa-sign-out-alt icon"> </i>
    <span class="title ml-2">{{ $t("dashboard.metrics.Departures.title").toUpperCase() }}</span>
    <div class="row mt-2">
      <div class="col">
        <span class="explain">Vandaag</span>
        <br />
        <span class="metric">
          <span> {{ today.Departed }}</span>
          <span class="suffix">/ {{ today.Departures }} </span>
        </span>
      </div>
      <div class="col">
        <span class="explain">Morgen</span>
        <br />
        <span class="metric">
          <span> {{ tomorrow.Departures }} </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const MetricRepository = RepositoryFactory.get("MetricRepository");
import moment from "moment";

export default {
  data() {
    return {
      today: {
        Departures: 0,
        Departed: 0
      },
      tomorrow: {
        Departures: 0,
        Departed: 0
      }
    };
  },

  computed: {
    ...mapState("Campsites", ["currentCampsite"])
  },

  watch: {
    currentCampsite() {
      this.getMetrics();
    }
  },

  mounted() {
    this.getMetrics();
  },

  methods: {
    getMetrics: function () {
      MetricRepository.getDepartures(this.currentCampsite.id, moment().startOf("day").toDate(), moment().endOf("day").toDate()).then((response) => {
        response.data.dimensions.forEach((e) => (this.today[e.name] = e.value));
      });
      MetricRepository.getDepartures(this.currentCampsite.id, moment().add({ days: 1 }).startOf("day").toDate(), moment().add({ days: 1 }).endOf("day").toDate()).then((response) => {
        response.data.dimensions.forEach((e) => (this.tomorrow[e.name] = e.value));
      });
    }
  }
};
</script>
