<template>
  <div>
    <b-dropdown variant="light" size="sm" right :text="rangeOptions[rangeOption].text" class="float-right">
      <b-dropdown-item v-for="(item, index) in rangeOptions" :key="index" @click="onRangeChanged(index)">{{ item.text }}</b-dropdown-item>
    </b-dropdown>
    <loading-spinner v-if="loading" :loading="loading" />

    <h1 class="text-light">A/V/V</h1>

    <b-tabs>
      <b-tab active>
        <template slot="title">
          Aankomsten <b-badge v-b-tooltip.hover variant="blue" title="Nog aan te komen/Totaal aan te komen"> {{ checkedInCount }}/{{ arrivalCount }}</b-badge>
        </template>
        <turnover-overview-table v-if="arrivingBookings.length" :bookings="arrivingBookings" :display-mode="config.turnoverDisplayMode.arrivals" />
        <b-alert v-else variant="light" class="mt-5" show>Geen aankomsten gevonden.</b-alert>
      </b-tab>
      <b-tab>
        <template slot="title">
          Vertrekken
          <b-badge v-b-tooltip.hover variant="blue" title="Nog te vertrekken/Totaal te vertrekken"> {{ checkedOutCount }}/{{ departureCount }}</b-badge>
        </template>
        <turnover-overview-table v-if="departingBookings.length" :bookings="departingBookings" :display-mode="config.turnoverDisplayMode.departures" />
        <b-alert v-else variant="light" class="mt-5" show>Geen vertrekken gevonden.</b-alert>
      </b-tab>
      <b-tab>
        <template slot="title">
          Verblijven <b-badge v-b-tooltip.hover variant="blue" title="Totaal van verblijven"> {{ stayingCount }}</b-badge>
        </template>
        <turnover-overview-table v-if="stayingBookings.length" :bookings="stayingBookings" />
        <b-alert v-else variant="light" class="mt-5" show>Geen verblijven gevonden.</b-alert>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import config from "@/config";
import { mapState } from "vuex";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const BookingRepository = RepositoryFactory.get("BookingRepository");

import LoadingSpinner from "@/components/common/LoadingSpinner.vue";
import TurnoverOverviewTable from "./TurnoverOverviewTable";

export default {
  components: { TurnoverOverviewTable, LoadingSpinner },

  data() {
    return {
      config: config,
      rangeOption: 0,
      arrivingBookings: [],
      departingBookings: [],
      stayingBookings: [],
      loading: false
    };
  },

  computed: {
    ...mapState("Campsites", ["currentCampsite"]),
    arrivalCount: function () {
      return this.arrivingBookings.length;
    },
    checkedInCount: function () {
      return this.$_.filter(this.arrivingBookings, (booking) => booking.checkInDate).length;
    },
    departureCount: function () {
      return this.departingBookings.length;
    },
    checkedOutCount: function () {
      return this.$_.filter(this.departingBookings, (booking) => booking.checkOutDate).length;
    },
    stayingCount: function () {
      return this.stayingBookings.length;
    },
    rangeOptions() {
      return [
        {
          text: "Vandaag",
          value: {
            from: this.$moment().startOf("day").toDate(),
            to: this.$moment().endOf("day").toDate()
          }
        },
        {
          text: "Morgen",
          value: {
            from: this.$moment().add({ days: 1 }).startOf("day").toDate(),
            to: this.$moment().add({ days: 1 }).endOf("day").toDate()
          }
        },
        {
          text: "Overmorgen",
          value: {
            from: this.$moment().add({ days: 2 }).startOf("day").toDate(),
            to: this.$moment().add({ days: 2 }).endOf("day").toDate()
          }
        },
        {
          text: "Komende 7 dagen",
          value: {
            from: this.$moment().startOf("day").toDate(),
            to: this.$moment().add({ days: 7 }).endOf("day").toDate()
          }
        }
      ];
    }
  },

  watch: {
    currentCampsite() {
      this.fetchBookings(this.rangeOptions[this.rangeOption].value);
    }
  },

  mounted() {
    this.fetchBookings(this.rangeOptions[this.rangeOption].value);
  },

  methods: {
    onRangeChanged(index) {
      this.rangeOption = index;

      this.fetchBookings(this.rangeOptions[this.rangeOption].value);
    },
    async fetchBookings(range) {
      this.loading = true;

      await BookingRepository.getArrivals(this.currentCampsite.id, range.from, range.to, 0, 99).then((bookings) => {
        this.arrivingBookings = bookings;
      });

      await BookingRepository.getDepartures(this.currentCampsite.id, range.from, range.to, 0, 99).then((bookings) => {
        this.departingBookings = bookings;
      });
      await BookingRepository.getStays(this.currentCampsite.id, range.from, range.to, 0, 99).then((bookings) => {
        this.stayingBookings = bookings;
      });

      this.loading = false;
    }
  }
};
</script>
